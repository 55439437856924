import * as React from "react"
import Layout from '../components/Layout';
import Card from '../components/Card';
import Pagination from '../components/Pagination';
import createSlug from '../utils/createSlug';
import Seo from '../components/Seo';

function IndexPage(props) {

  const { group, index, first, last, pagePathPrefix, pagePathRoot } = props.pageContext;

  const previousUrl = index - 1 > 1 ? `${pagePathPrefix}/${index - 1}` : pagePathRoot;
  const nextUrl = `${pagePathPrefix}/${index + 1}`;

  return (
    <Layout>
      <Seo />
      {group.map(a => <Card
        key={`card-${a.node.fields.slug}`}
        slug={a.node.fields.slug}
        title={a.node.frontmatter.title}
        date={new Date(a.node.frontmatter.date)}
        content={a.node.excerpt}
        categories={a.node.frontmatter.categories?.map(a => {
          return {
            title: a,
            slug: createSlug(a)
          }
        })}
        tags={a.node.frontmatter.tags?.map(a => {
          return {
            title: a,
            slug: createSlug(a)
          }
        })}
      />)}

      <Pagination isFirst={first} isLast={last} index={index} previousUrl={previousUrl} nextUrl={nextUrl} previousText={`Page ${index - 1}`} nextText={`Page ${index + 1}`} />

    </Layout>
  );
}

export default IndexPage;